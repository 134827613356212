<template>
  <div class="Questionnaire todo">
    <div class="item-header flex">
      <div class="screen-list-item flex">
        <div class="flex" style="flex:1">
          <span class="screen-item-label">所属分类</span>
          <a-tree-select
            style="flex:1"
            :treeData="classifyList"
            v-model="classify"
            :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
            placeholder="请选择"
            allowClear
            treeDefaultExpandAll
            @change="_classify"
          >
          </a-tree-select>
        </div>
      </div>
      <div class="screen-list-item flex">
        <span class="screen-item-label">发布时间</span>
        <a-range-picker
          style="flex: 1"
          format="YYYY-MM-DD"
          :placeholder="['开始时间', '结束时间']"
          allowClear
          @change="_createdDate"
        />
      </div>
      <div class="screen-list-item flex">
        <span class="screen-item-label">主题名称</span>
        <a-input
          placeholder="请输入关键字"
          style="flex: 1;height: 36px;"
          v-model="searchInput"
        />
      </div>
      <div class="screen-list-item">
        <a-button style="margin-right:20px" @click="_reset">重置</a-button>
        <a-button type="primary" @click="_searchData">查询</a-button>
      </div>
    </div>

    <div class="Questionnaire-content">
      <div class="content-top flex">
        <div class="content-title flex">
          <span>考试问卷</span>
          <span style="color: #999">
            <i
              class="iconfont icon-tubiao_kapian"
              :class="showType == 1 ? 'ant-menu-item-selected' : ''"
              @click="_showType(1)"
            ></i>
            <i
              class="iconfont icon-tubiao_liebiao"
              :class="showType == 2 ? 'ant-menu-item-selected' : ''"
              @click="_showType(2)"
            ></i>
          </span>
          <span style="margin-left:10px" v-if="isqbAuth == 1">
            <a-button type="primary" style="height:28px"
              ><router-link to="/QBHR_Questionnaire"
                >随机试题</router-link
              ></a-button
            >
          </span>
        </div>

        <div>
          <a-button type="primary"
            ><router-link to="/selectQuestionnaire"
              >新建项目</router-link
            ></a-button
          >
        </div>
      </div>

      <div class="tabs-list" v-show="showType == 1">
        <div
          v-if="!infoData || infoData.length == 0"
          style="font-size: 25px;color: #ccc;margin: 20px 0;text-align: center"
        >
          暂无数据
        </div>
        <ul class="operation">
          <li
            @mouseenter="_cutShowItem(index)"
            @mouseleave="showItem = -1"
            v-for="(item, index) in infoData"
          >
            <div
              class="tabs-type"
              :style="item.TypeName == '试卷' ? 'background: #FF7F38' : ''"
            >
              {{ item.TypeName }}
            </div>
            <div class="tabs-content">{{ item.Title }}</div>
            <div class="created-time">{{ item.CreateTime }}</div>
            <div v-show="showItem != index" class="tabs-footer">
              <span>{{ item.RealCount }}份数据</span>
              <div
                class="tabs-status"
                :style="item.Enable == '未发布' ? 'color: #FF7F38' : ''"
              >
                <i
                  :style="item.Enable == '未发布' ? 'background: #FF7F38' : ''"
                ></i>
                {{ item.Enable }}
              </div>
            </div>
            <div
              v-show="showItem == index"
              class="tabs-footer"
              id="tabs-footer"
            >
              <div class="tabs-btn-list" @mouseout="_hoverActive(-1)">
                <div
                  @mouseover="_hoverActive(1)"
                  @click="_clickActive(1, item)"
                >
                  <div class="tabs-btn-icon">
                    <i
                      class="iconfont icon-bianji"
                      :class="hoverActive == 1 ? 'ant-menu-item-selected' : ''"
                    ></i>
                  </div>
                  <div
                    class="tabs-btn-text"
                    :class="hoverActive == 1 ? 'ant-menu-item-selected' : ''"
                  >
                    编辑
                  </div>
                </div>

                <div
                  @mouseover="_hoverActive(2)"
                  @click="_clickActive(2, item)"
                >
                  <div class="tabs-btn-icon">
                    <i
                      class="iconfont icon-tubiao_fabu"
                      :class="hoverActive == 2 ? 'ant-menu-item-selected' : ''"
                    ></i>
                  </div>
                  <div
                    class="tabs-btn-text"
                    :class="hoverActive == 2 ? 'ant-menu-item-selected' : ''"
                  >
                    链接
                  </div>
                </div>

                <div
                  @mouseover="_hoverActive(3)"
                  @click="_clickActive(3, item)"
                >
                  <div class="tabs-btn-icon">
                    <i
                      class="iconfont icon-tubiao_shuju"
                      :class="hoverActive == 3 ? 'ant-menu-item-selected' : ''"
                    ></i>
                  </div>
                  <div
                    class="tabs-btn-text"
                    :class="hoverActive == 3 ? 'ant-menu-item-selected' : ''"
                  >
                    数据
                  </div>
                </div>

                <div
                  @mouseover="_hoverActive(4)"
                  @click="_clickActive(4, item)"
                  v-if="item.TypeName == '试卷' && item.isAuth == 1"
                >
                  <div class="tabs-btn-icon">
                    <i
                      class="iconfont icon-tubiao_pigai"
                      :class="hoverActive == 4 ? 'ant-menu-item-selected' : ''"
                    ></i>
                  </div>
                  <div
                    class="tabs-btn-text"
                    :class="hoverActive == 4 ? 'ant-menu-item-selected' : ''"
                  >
                    批改
                  </div>
                </div>
              </div>
              <a-dropdown
                :placement="'topLeft'"
                :getPopupContainer="triggerNode => triggerNode.parentNode"
              >
                <i class="iconfont icon-shenglve" style="font-size: 20px"></i>
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a href="javascript:;" @click="_issueProject(item)">{{
                      item.Enable == '未发布' ? '开始发布' : '停止发布'
                    }}</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;" @click="_preview(item)">预览</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;" @click="_copy(index)">复制</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;" @click="_del(item)">删除</a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </div>
          </li>
        </ul>
      </div>

      <div style="margin-top:15px;" v-show="showType == 2">
        <a-table
          :columns="columns"
          :dataSource="infoData"
          :pagination="false"
          bordered
        >
          <div slot="action" slot-scope="text, record" style="color: #B1B1B1;">
            <i
              class="iconfont icon-tubiao_bianji icon-btn"
              :class="hoverActive == 1 ? 'ant-menu-item-selected' : ''"
              @click="_clickActive(1, record)"
            ></i>
            <i
              class="iconfont icon-tubiao_fabu icon-btn"
              :class="hoverActive == 2 ? 'ant-menu-item-selected' : ''"
              @click="_clickActive(2, record)"
            ></i>
            <i
              class="iconfont icon-tubiao_shuju icon-btn"
              @click="_clickActive(3, record)"
            ></i>
            <i
              class="iconfont icon-tubiao_pigai icon-btn"
              v-if="record.TypeName != '问卷'"
              @click="_clickActive(4, record)"
            ></i>
            <a-dropdown
              :placement="'topLeft'"
              :getPopupContainer="triggerNode => triggerNode.parentNode"
            >
              <i
                class="iconfont icon-shenglve icon-btn"
                style="font-size: 20px"
              ></i>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a href="javascript:;" @click="_issueProject(record)">{{
                    record.Enable == '未发布' ? '开始发布' : '停止发布'
                  }}</a>
                </a-menu-item>
                <a-menu-item>
                  <a href="javascript:;" @click="_preview(record)">预览</a>
                </a-menu-item>
                <a-menu-item>
                  <a href="javascript:;">复制</a>
                </a-menu-item>
                <a-menu-item>
                  <a-popconfirm
                    placement="top"
                    cancelText="取消"
                    okText="确定"
                    @confirm="_del(record)"
                  >
                    <template slot="title">
                      <p>确定删除么？</p>
                    </template>
                    删除
                  </a-popconfirm>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
          <div slot="Enable" slot-scope="text, record" style="color: #666">
            <i
              style="display: inline-block;width: 7px;height: 7px;border-radius: 50%;margin-right:4px"
              :style="
                record.Enable == '未发布'
                  ? 'background: #FF7F38'
                  : 'background: #52C41A'
              "
            ></i>
            {{ record.Enable }}
          </div>
        </a-table>
      </div>
      <div style="margin: 20px 0;" v-if="pageTotal > 10">
        <a-pagination
          showQuickJumper
          :defaultCurrent="1"
          :total="pageTotal"
          @change="_PageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
var user = JSON.parse(window.sessionStorage.getItem('userInfo'))
export default {
  name: 'Questionnaire',
  data () {
    return {
      searchInput: '',
      startEndDate: '',
      startDate: '',
      endDate: '',
      classify: '',
      pageTotal: 0,
      page: 1,
      classifyList: [],
      columns: [
        {
          title: '序号',
          dataIndex: 'sNumber',
          rowKey: 'sNumber',
          align: 'center',
          width: 80
        },
        {
          title: '试卷名称',
          dataIndex: 'Title',
          rowKey: 'Title',
          align: 'center',
          width: 300
        },
        {
          title: '类型',
          dataIndex: 'TypeName',
          rowKey: 'TypeName',
          align: 'center',
          width: 300
        },
        {
          title: '所属分类',
          dataIndex: 'CategoryName',
          rowKey: 'CategoryName',
          align: 'center',
          width: 180
        },
        {
          title: '状态',
          scopedSlots: { customRender: 'Enable' },
          align: 'center',
          width: 180
        },
        {
          title: '收到数据',
          dataIndex: 'RealCount',
          rowKey: 'RealCount',
          align: 'center',
          width: 180
        },
        {
          title: '创建人',
          dataIndex: 'CreateUserName',
          rowKey: 'CreateUserName',
          align: 'center',
          width: 180
        },
        {
          title: '创建时间',
          dataIndex: 'CreateTime',
          rowKey: 'CreateTime',
          align: 'center',
          width: 200
        },
        {
          title: '编辑时间',
          dataIndex: 'LastTime',
          rowKey: 'LastTime',
          align: 'center',
          width: 200
        },
        {
          title: '操作栏',
          dataIndex: '',
          rowKey: 'x',
          scopedSlots: { customRender: 'action' },
          width: 200
        }
      ],
      infoData: [],
      showType: 1,
      hoverActive: -1,
      showItem: -1,
      rangeDate: [],
      isqbAuth: 0
    }
  },
  created () {
    let user = JSON.parse(window.sessionStorage.getItem('userInfo'))
    this._info()
    //获取考试分类
    this.$axios.get(1206, { label: 'Questionnaire' }, res => {
      if (res.data.code == 1) {
        this.classifyList = res.data.data
      }
    })
  },
  methods: {
    _issueProject (value) {
      //发布项目
      let data = {
        UserId: JSON.parse(window.sessionStorage.getItem('userInfo')).uid,
        Id: value.Id,
        changeType: 'change',
        Status: value.Enable == '未发布' ? 1 : 0
      }
      let self = this
      this.$axios.post(5836110, data, res => {
        if (res.data.code == 1) {
          this.infoData[value.key - 1].Enable =
            value.Enable == '未发布' ? '已发布' : '未发布'
          self.$message.success(res.data.msg)
        } else {
          self.$message.error(res.data.msg)
        }
      })
    },
    _setData (value) {
      let type = value.TypeName == '问卷' ? 1 : 2
      this.$router.push({
        path: '/addQuestionnaire',
        query: { type: type, id: value.Id }
      })
    },
    _del (value) {
      //删除
      let data = {
        UserId: JSON.parse(window.sessionStorage.getItem('userInfo')).uid,
        Id: value.Id
      }
      let self = this
      this.$axios.post(1229, data, res => {
        if (res.data.code == 1) {
          this.infoData.splice(value.key - 1, 1)
          self.$message.success(res.data.msg)
        } else {
          self.$message.error(res.data.msg)
        }
      })
    },
    _copy (i) {
      //复制
      this.$axios.post(
        5836102,
        { Qid: this.infoData[i].Id, UserId: user.uid, UserName: user.name },
        res => {
          if (res.data.code == 1) {
            let item = JSON.stringify(this.infoData[i])
            item = JSON.parse(item)
            item.Id = res.data.data
            item.sNumber = this.infoData.length + 1
            item.key = this.infoData.length + 1
            item.Enable = '未发布'
            item.CreateTime = this.__moment__(new Date()).format(
              'YYYY-MM-DD HH:mm'
            )
            this.infoData.push(item)
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
        }
      )
    },
    _preview (value) {
      let type = value.TypeName == '问卷' ? 1 : 2
      this.$router.push({
        path: '/addQuestionnaire',
        query: { type: type, id: value.Id, preview: 1 }
      })
    },
    _reset () {
      this.startDate = ''
      this.endDate = ''
      this.searchInput = ''
      this.Type = ''
    },
    _searchData () {
      this.page = 1
      this.infoData = []
      this.$message.loading('加载中...', 0)
      let data = {
        Keyword: this.searchInput,
        CategoryId: this.classify,
        Type: '',
        startDate: this.startDate,
        endDate: this.endDate,
        Page: this.page,
        UserId: user.uid
      }
      this.$axios.get(1228, data, res => {
        this.infoData = res.data.data || []
        this.pageTotal = res.data.count || 0
        this.$message.destroy()
      })
    },
    _info () {
      this.$message.loading('加载中...', 0)
      let data = {
        Keyword: this.searchInput,
        CategoryId: this.classify,
        Type: '',
        startDate: this.startDate,
        endDate: this.endDate,
        UserId: user.uid,
        Page: this.page
      }
      this.$axios.get(1228, data, res => {
        if (res.data.code == 1) {
          this.page += 1
        }
        this.isqbAuth = res.data.isqbAuth
        this.infoData = res.data.data
        this.pageTotal = res.data.count
        this.$message.destroy()
      })
    },
    _cutShowItem (i) {
      this.showItem = i
    },
    _issue (val) {
      this.$router.push({ path: '/addCorrect', query: { id: val.Id } })
    },
    _clickActive (i, value) {
      if (i == 3) {
        this.$router.push({
          path: '/statisticsQuestionnaire',
          query: { id: value.Id, type: value.TypeName == '试卷' ? 2 : 1 }
        })
      } else if (i == 4) {
        this.$router.push({ path: '/addCorrect', query: { id: value.Id } })
      } else if (i == 1) {
        this._setData(value)
      } else if (i == 2) {
        this.$router.push({
          path: '/issueQuestionnaire',
          query: { id: value.Id, type: value.TypeName == '试卷' ? 2 : 1 }
        })
      }
    },
    _hoverActive (i) {
      this.hoverActive = i
    },
    _showType (i) {
      this.showType = i
    },
    _PageChange (pageNumber) {
      this.page = pageNumber
      this._info()
    },
    _createdDate (value) {
      this.startEndDate = value
      this.startDate = value[0]._d
      this.endDate = value[1]._d
      let date1 = new Date(this.startDate)
      this.startDate =
        date1.getFullYear() +
        '-' +
        (date1.getMonth() + 1 < 10
          ? '0' + (date1.getMonth() + 1)
          : date1.getMonth() + 1) +
        '-' +
        (date1.getDate() < 10 ? '0' + date1.getDate() : date1.getDate())
      let date2 = new Date(this.endDate)
      this.endDate =
        date2.getFullYear() +
        '-' +
        (date2.getMonth() + 1 < 10
          ? '0' + (date2.getMonth() + 1)
          : date2.getMonth() + 1) +
        '-' +
        (date2.getDate() < 10 ? '0' + date2.getDate() : date2.getDate())
    },
    _classify (value) {
      this.classify = value
    }
  }
}
</script>

<style scoped lang="less">
.Questionnaire {
  height: 100%;
  .item-header {
    padding: 15px 20px;
    background: #fff;
    border-radius: 4px;
    .screen-list-item {
      margin-right: 20px;
      flex: 1;
    }
    .screen-item-label {
      margin-right: 10px;
      color: #666;
      font-size: 14px;
      white-space: nowrap;
    }
  }
  .Questionnaire-content {
    padding: 15px;
    background: #fff;
    margin-top: 20px;
    border-radius: 4px;
    min-height: calc(~'100% - 80px');
    .tabs-list {
      .operation {
        > li {
          width: 300px;
          height: 180px;
          padding: 0 20px;
          display: inline-block;
          position: relative;
          vertical-align: top;
          margin-bottom: 15px;
          border: 1px solid #e7e9ec;
          border-radius: 3px;
          margin-right: 20px;
          cursor: pointer;
          .tabs-content {
            font-size: 16px;
            color: #666;
          }
          .created-time {
            margin-top: 10px;
            color: #999;
            font-size: 13px;
          }
          .tabs-type {
            width: 36px;
            padding: 1px 0;
            margin-bottom: 20px;
            text-align: center;
            font-size: 12px;
            background: #7672f9;
            color: #fff;
          }
          .tabs-footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: calc(~'100% - 40px');
            position: absolute;
            bottom: 15px;
            color: #999;
            .tabs-status {
              color: #75c255;
              i {
                display: inline-block;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: #75c255;
                vertical-align: middle;
              }
            }
            .tabs-btn-list {
              > div {
                display: inline-block;
                text-align: center;
                margin-right: 15px;
                cursor: pointer;
                .tabs-btn-text {
                  font-size: 12px;
                }
                .tabs-btn-icon {
                  font-size: 18px;
                  i {
                    font-size: 22px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .content-title {
      margin-bottom: 10px;
      span {
        font-size: 18px;
        color: #333;
      }
      i {
        margin-left: 10px;
        font-size: 26px;
        cursor: pointer;
      }
    }
    .icon-btn {
      font-size: 20px;
      margin-right: 8px;
      cursor: pointer;
    }
  }
}
</style>
